import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import YellowDots from "../../static/images/explore-yellow-dots.svg"
import PinkLine from "../../static/images/explore-pink-line.svg"
import YellowDot from "../../static/images/explore-yellow-dot.svg"
import BlueDots from "../../static/images/explore-blue-dots.svg"

const Contact = () => (
  <Layout>
  <Helmet>
    <title>Contact us | IBDmate</title>
  </Helmet>
    <div className="layout-sign-up">
      <div className="purple-bg">
        <div className="content">
          <h1>Contact</h1>
        </div>
        <div className="vectors-left">
          <img src={YellowDots} id="yellow-dots" alt="" />
          <img src={PinkLine} id="pink-line" alt="" />
        </div>
        <div className="vectors-right">
          <img src={YellowDot} id="yellow-dot" alt="" />
          <img src={BlueDots} id="blue-dots" alt="" />
        </div>
      </div>
      <div className="white-bg">
        <div className="wrapper">
          <form method="POST" netlify-honeypot="bot-field" data-netlify="true" name="contact" action="/thank-you/">
            <input type="hidden" name="subject-field" />
            <input type="hidden" name="form-name" value="contact" />
            <p>We love to hear from you, whether that’s feedback, questions, ideas or you just want to say hi.</p>
            <p><a href="mailto:hello@ibdmate.com">hello@ibdmate.com</a></p>
            <p>Drop us a message and we’ll be in touch.</p>
            <div className="form-field">
              <input
                  type="text"
                  placeholder="First name"
                  name="f_name"
                  className="input"
              />
            </div>
            <div className="form-field">
              <input
                  type="text"
                  placeholder="Surname"
                  name="s_name"
                  className="input"
              />
            </div>
            <div className="form-field">
              <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  className="input"
              />
            </div>
            <div className="form-field">
              <textarea className="input" name="message" rows="5" placeholder="Message"></textarea>
            </div>
            <button type="submit" className="btn btn-purple btn-lg">Send</button>
          </form>
        </div>
      </div>
    </div>
  </Layout>
  )

  export default Contact
